<script>
  import { onMount, onDestroy } from 'svelte'
  import SimpleMDE from 'simplemde'
  import 'simplemde/dist/simplemde.min.css'

  export let value
  let input, mde

  onMount(() => {
    mde = new SimpleMDE({
      element: input,
      spellChecker: false
    })

    mde.codemirror.on('change', () => {
      value = mde.value()
    })
  })

  onDestroy(() => {
    mde.toTextArea()
  })
</script>

<textarea bind:this={input} {value} />
