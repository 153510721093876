<script>
  import { onMount } from 'svelte'
  import { navigate } from 'svelte-routing'
  import { getClient } from 'helpers/client'
  import getPage from 'queries/getPage'
  import updatePage from 'mutations/updatePage'
  import MarkdownInput from 'components/MarkdownInput'

  export let id

  const client = getClient()
  let page

  onMount(async () => {
    const result = await client.query({ query: getPage, variables: { id } })
    page = result.data.page || { id, title: id, body: '' }
  })

  async function submit () {
    const result = await client.mutate({
      mutation: updatePage,
      variables: {
        id: page.id,
        title: page.title,
        body: page.body
      }
    })
    client.writeQuery({
      query: getPage,
      variables: { id: result.data.updatePage.page.id },
      data: result.data.updatePage
    })
    navigate(`/wiki/${result.data.updatePage.page.id}`)
  }
</script>

{#if page}
  <div class='form-group'>
    <label>標題</label>
    <input type='text' class='form-control' required bind:value={page.title} />
  </div>

  <div class='form-group'>
    <label>內文</label>
    <MarkdownInput bind:value={page.body} />
  </div>

  <button class='btn btn-primary' on:click={submit}>送出</button>
{/if}
