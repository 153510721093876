<script>
  import Modal from 'components/Modal'
  import Query from 'components/Query'
  import Avatar from 'components/Avatar'
  import getUser from 'queries/getUser'
  import showUser from 'stores/showUser'
  import Tags from './Tags'
  import OpenChatroomButton from './OpenChatroomButton'
  import BlockButton from './BlockButton'

  function hideUser () {
    $showUser = false
  }
</script>

<style>
  .bio {
    max-height: 300px;
    overflow-y: scroll;
  }
</style>

{#if $showUser}
  <Query query={getUser} variables={{ id: $showUser }} let:result>
    <Modal visible={$showUser} on:close={hideUser}>
      <div slot='title'>{result.data.user.nickname}</div>
      <div slot='body'>
        <div class='media'>
          <div class='media-left mr-3'>
            <Avatar user={result.data.user} size='60' />
          </div>
          <div class='media-body'>
            <h4>{result.data.user.nickname}</h4>
            <div class='text-muted small'>
              #{result.data.user.id}
            </div>
            <Tags user={result.data.user} />
            <div class='bio'>{result.data.user.bio}</div>
            <OpenChatroomButton user={result.data.user} />
            <BlockButton user={result.data.user} />
          </div>
        </div>
      </div>
    </Modal>
  </Query>
{/if}
