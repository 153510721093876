<script>
  import { getClient } from 'helpers/client'
  import blockUser from 'mutations/blockUser'
  import unblockUser from 'mutations/unblockUser'
  import BlockQuery from 'components/BlockQuery'

  export let user

  async function block () {
    await getClient().mutate({
      mutation: blockUser,
      variables: { userId: user.id }
    })
  }

  async function unblock () {
    await getClient().mutate({
      mutation: unblockUser,
      variables: { userId: user.id }
    })
  }
</script>

<BlockQuery {user}>
  <button slot='unblocked' class='btn btn-secondary' on:click={block}>
    封鎖
  </button>
  <button slot='blocked' class='btn btn-secondary' on:click={unblock}>
    解除封鎖
  </button>
</BlockQuery>
