<script>
  import { getClient } from 'helpers/client'
  import openChatroom from 'mutations/openChatroom'
  import showUser from 'stores/showUser'
  import { updateChatroom } from 'stores/openingChatrooms'

  export let user

  const client = getClient()

  async function mutate () {
    const response = await client.mutate({
      mutation: openChatroom,
      variables: { userId: user.id }
    })
    const { data: { openChatroom: { chatroom } } } = response
    $showUser = null
    updateChatroom(chatroom.id)
  }
</script>

<slot mutate={mutate}></slot>
