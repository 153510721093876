<script>
  import { getClient } from 'helpers/client'
  import updateProfile from 'mutations/updateProfile'

  export let profile

  let { nickname, email, bio } = profile

  export function submit () {
    getClient().mutate({
      mutation: updateProfile,
      variables: { nickname, email, bio }
    })
  }
</script>

<div class='form-group'>
  <label for='nickname'>暱稱</label>
  <input name='nickname' type='text' class='form-control'
      bind:value={nickname}>
</div>

<div class='form-group'>
  <label for='email'>Email</label>
  <input name='email' type='text' class='form-control'
      bind:value={email} />
  <div class='form-text small text-muted'>
    我們只會使用這個 Email 產生頭像, 請參考
    <a href='https://zh-tw.gravatar.com/' target='_blank'>Gravatar</a>
    的使用說明.
  </div>
</div>

<div class='form-group'>
  <label for='bio'>自我介紹</label>
  <textarea name='bio' class='form-control' bind:value={bio} />
</div>
