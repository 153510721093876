<script>
  import { link } from 'svelte-routing'

  export let board
</script>

<a
  href='/boards/{board.slug}'
  class='list-group-item list-group-item-action'
  use:link>
  <h4>{board.name}</h4>
  <div>{board.bio}</div>
  <div>線上人數: {board.onlineUsersCount}</div>
</a>
