<script>
  import { link } from 'svelte-routing'
  import PageQuery from 'components/PageQuery'
  import DateTime from 'components/DateTime'

  export let id
</script>

<div class='row'>
  <div class='col-9'>
    <PageQuery {id} let:page>
      {#if page}
        <h1>{page.title}</h1>

        {@html page.bodyHTML}

        <div class='small text-muted'>
          更新於 <DateTime datetime={page.updatedAt} />
        </div>
      {:else}
        <p>
          本條目尚未被建立!
          <a href={`/wiki/${id}/edit`} use:link>來去寫一下</a>
        </p>
      {/if}
    </PageQuery>
  </div>

  <div class='col-3'>
    <a href='/wiki' use:link>條目列表</a>
    <hr />
    <a href={`/wiki/${id}/edit`} use:link>編輯</a>
  </div>
</div>
