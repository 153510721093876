<script>
  import { Link } from 'svelte-routing'
  import getPopularBoards from 'queries/getPopularBoards'

  import Query from 'components/Query'
  import BoardLink from 'components/BoardLink'
</script>

<h2>熱門主題</h2>

<Query query={getPopularBoards} variables={{ limit: 5 }} let:result>
  <div class='list-group'>
    {#each result.data.popularBoards as board (board.id)}
      <BoardLink {board} />
    {/each}
  </div>
</Query>

<Link to='/boards'>我要看更多/我要建立主題</Link>
