<script>
  import openingChatrooms, { updateChatroom } from 'stores/openingChatrooms'
  import ChatroomBox from './ChatroomBox'

  function handleDragOver (e) {
    e.preventDefault()
  }

  function handleDrop (e) {
    const data = JSON.parse(event.dataTransfer.getData('text/plain'))
    const x = data.x + e.clientX - data.clientX
    const y = data.y + e.clientY - data.clientY
    updateChatroom(data.id, x, y)
  }
</script>

{#each $openingChatrooms as chatroom (chatroom.id)}
  <ChatroomBox {...chatroom} />
{/each}

<svelte:body on:dragover={handleDragOver} on:drop={handleDrop} />
