<script>
  import Query from 'components/Query'
  import getPage from 'queries/getPage'

  export let id
</script>

<Query query={getPage} variables={{ id }} let:result>
  <slot page={result.data.page}></slot>
</Query>
