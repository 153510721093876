<script>
  import BoardLoader from './BoardLoader'
  import Layout from './Layout'

  export let id
</script>

<BoardLoader {id} let:board>
  {#if board}
    <Layout {board} />
  {:else}
    <p>無法載入主題, 可能是以下原因:</p>
    <ul>
      <li>你未登入, 沒有足夠的權限建立新主題</li>
      <li>你沒有足夠的權限閱讀主題</li>
    </ul>
  {/if}
</BoardLoader>
