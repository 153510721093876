<script>
  import { createEventDispatcher } from 'svelte'
  import showImage from 'stores/showImage'
  import UserLink from 'components/UserLink'
  import BlockQuery from 'components/BlockQuery'
  import DateTime from 'components/DateTime'
  import Metadata from './Metadata'

  export let message

  const dispatch = createEventDispatcher()

  function dispatchLoad () {
    dispatch('load')
  }
</script>

<style>
  .message:nth-child(even) {
    background-color: var(--light);
  }

  .message-timestamp {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }

  .message-body {
    overflow-wrap: break-word;
  }
</style>

<BlockQuery user={message.user}>
  <div class='message py-1' slot='unblocked'>
    <div class='message-timestamp small text-muted pl-1 pb-1 float-right'>
      <DateTime datetime={message.createdAt} format='LTS' />
    </div>

    {#if message.type === 'EnterMessage'}
      <div class='text-muted small'>
        {message.nickname} 進來了.
      </div>
    {/if}

    {#if message.type === 'LeaveMessage'}
      <div class='text-muted small'>
        {message.nickname} 離開了.
      </div>
    {/if}

    {#if message.type === 'RenameMessage'}
      <div class='text-muted small'>
        {message.nicknameWas} 改名為 {message.nickname}.
      </div>
    {/if}

    {#if message.type === 'ChatMessage'}
      <div>
        <UserLink user={message.user}
            class='d-inline' style={`color: ${message.color}`}>
          {message.nickname}
        </UserLink>:
        <div class='message-body d-inline'>{@html message.bodyHTML}</div>

        {#if message.urlMetadata && $showImage}
          <Metadata {...message.urlMetadata} on:load={dispatchLoad} />
        {/if}
      </div>
    {/if}
  </div>
</BlockQuery>
