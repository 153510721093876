<script>
  import { onMount } from 'svelte'
  import GetProfileQuery from 'components/GetProfileQuery'
  import { Router, Route } from 'svelte-routing'

  import Connection from 'components/Connection'
  import NavBar from 'components/NavBar'
  import Messenger from 'components/Messenger'
  import ChatroomBoxes from 'components/ChatroomBoxes'
  import EditProfileModal from 'components/EditProfileModal'
  import UserModal from 'components/UserModal'

  import Boards from './Boards'
  import Board from './Board'
  import Pages from './Pages'
  import Page from './Page'
  import EditPage from './EditPage'
  import Uploads from './Uploads'
  import Chatroom from './Chatroom'
  import Donation from './Donation'
  import About from './About'
  import Home from './Home'
  import NotFound from './NotFound'

  let height

  function updateHeight () {
    height = window.innerHeight
  }

  onMount(updateHeight)
</script>

<GetProfileQuery fetchPolicy='network-only' let:profile>
  <div class='d-flex flex-column' style='min-height: {height}px'>
    <Connection />
    <NavBar />

    <div class='container-fluid flex-fill position-relative'>
      <Router>
        <Route path='/boards' component={Boards} />
        <Route path='/boards/:id' component={Board} />
        <Route path='/wiki' component={Pages} />
        <Route path='/wiki/:id' component={Page} />
        <Route path='/wiki/:id/edit' component={EditPage} />
        <Route path='/uploads' component={Uploads} />
        <Route path='/chatrooms/:id' component={Chatroom} />
        <Route path='/donation' component={Donation} />
        <Route path='/about' component={About} />
        <Route path='/' component={Home} />

        <Route path='*' component={NotFound} />
      </Router>
    </div>
  </div>

  {#if profile}
    <Messenger />
    <ChatroomBoxes />
    <EditProfileModal />
  {/if}
  <UserModal />
</GetProfileQuery>

<svelte:window on:resize={updateHeight} />
