<script>
  import Query from 'components/Query'
  import getMessages from 'queries/getMessages'

  export let board

  function filterImages (messages) {
    return messages.filter(message =>
      message.urlMetadata && message.urlMetadata.type === 'image'
    ).reverse()
  }
</script>

<style>
  .image-item {
    height: 200px;
  }

  .image {
    object-fit: cover;
  }
</style>

<Query query={getMessages} fetchPolicy='cache-only'
    variables={{ channelName: board.channelName }} let:result>
  <div class='d-flex flex-wrap'>
    {#each filterImages(result.data.messages) as message (message.id)}
      <a href={message.urlMetadata.url} target='_blank'
          class='image-item d-inline-block'>
        <img src={message.urlMetadata.url}
            alt={message.urlMetadata.url}
            class='image mw-100 mh-100' />
      </a>
    {/each}
  </div>
</Query>
