import { getAccessToken } from 'helpers/accessToken'

export default async function (file) {
  const formData = new FormData()
  formData.append('image', file)
  const response = await fetch('/upload', {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  })
  return response.json()
}
