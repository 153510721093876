import storable from 'helpers/storable'

const openingChatrooms = storable('openingChatrooms', [])

export default openingChatrooms

export function updateChatroom (id, x, y) {
  openingChatrooms.update($openingChatrooms => {
    if (!$openingChatrooms) {
      $openingChatrooms = []
    }
    let chatroom = {
      ...create(id),
      ...$openingChatrooms.find(c => c.id === id)
    }
    if (x && y) {
      chatroom = { ...chatroom, x, y }
    }
    return [
      ...$openingChatrooms.filter(c => c.id !== id),
      chatroom
    ]
  })
}

function create (id) {
  const x = (window.innerWidth - 400) / 2
  const y = (window.innerHeight - 400) / 2

  return { id, x, y }
}

export function removeChatroom (id) {
  openingChatrooms.update($openingChatrooms =>
    $openingChatrooms.filter(c => c.id !== id)
  )
}
