<script>
  import isMobile from 'stores/isMobile'
  import GetProfileQuery from 'components/GetProfileQuery'
  import Breath from './Breath'
  import Toolbar from './Toolbar'
  import OnlineUsers from './OnlineUsers'
  import MessageSubscription from 'components/MessageSubscription'
  import Contents from './Contents'
  import MessageForm from './MessageForm'

  export let board
</script>

<style>
  .fill {
    left: 1rem;
    right: 1rem;
    top: 0;
    bottom: 1rem;
  }
</style>

<GetProfileQuery let:profile>
  {#if profile}
    <Breath {board} />
  {/if}
</GetProfileQuery>

<div class='position-absolute d-flex flex-column fill'>
  <h1 class='h6'>{board.name}</h1>
  <Toolbar {board} />
  <div class='flex-1 d-flex overflow-y-hidden'>
    {#if $isMobile}
      <MessageSubscription channelName={board.channelName} />
    {:else}
      <OnlineUsers {board} />
      <MessageSubscription channelName={board.channelName} />
      <Contents {board} />
    {/if}
  </div>

  <MessageForm {board} />
</div>
