import { ApolloClient } from 'apollo-client'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import createHttpLink from './createHttpLink'
import createActionCableLink from './createActionCableLink'
import createCache from './createCache'

export default async function createClient ({ cable, endpoint }) {
  const cableLink = createActionCableLink(cable)
  const httpLink = createHttpLink(endpoint)

  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' &&
        operation === 'subscription'
    },
    cableLink,
    httpLink
  )

  const cache = await createCache()

  const apolloClient = new ApolloClient({
    link,
    cache,
    connectToDevTools: process.env.NODE_ENV !== 'production'
  })

  return apolloClient
}
