<script context='module'>
  import moment from 'moment'

  moment.locale('zh-TW')
</script>

<script>
  export let datetime
  export let format = 'lll'

  $: result = moment(datetime).format(format)
</script>

{result}
