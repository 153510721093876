<script>
  import showUser from 'stores/showUser'

  export let user
  export let style

  function handleClick () {
    $showUser = user.id
  }
</script>

<style>
  .user-link {
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .user-link:hover {
    background-color: #ffc;
  }
</style>

<div class='{$$props.class} user-link' {style} on:click={handleClick}>
  <slot></slot>
</div>
