<script>
  import dotProp from 'dot-prop-immutable'
  import showMessenger from 'stores/showMessenger'
  import getChatrooms from 'queries/getChatrooms'
  import Query from 'components/Query'
  import Item from './Item'
  import unreadCountUpdated from 'subscriptions/unreadCountUpdated'

  let query

  const subscribeToMore = {
    document: unreadCountUpdated,
    updateQuery: (prev, { subscriptionData }) => {
      const nextChatroom = subscriptionData.data.unreadCountUpdated.chatroom
      return dotProp.set(
        prev,
        'profile.chatrooms',
        // remove original and push on the top
        [nextChatroom,
          ...prev.profile.chatrooms
            .filter(chatroom => chatroom.id !== nextChatroom.id)]
      )
    }
  }

  function refetch () {
    query && query.refetch()
  }
</script>

<style>
  .messenger {
    right: -5.5em;
    top: 4em;
    z-index: 500;
    background: white;

    border: 1px solid rgba(0, 0, 0, 0.125);
    border-right: 0;
    border-radius: 0.25em 0 0 0.25em;

    transition: right .2s;
  }

  .messenger-empty {
    right: 0;
  }

  .messenger:hover {
    right: 0;
  }
</style>

<svelte:body on:reconnect={refetch} />

{#if $showMessenger}
  <Query query={getChatrooms} fetchPolicy='cache-and-network' {subscribeToMore}
      bind:this={query} let:result>
    <div class='position-fixed messenger'
        class:messenger-empty={result.data.profile.chatrooms.length === 0}>
      {#each result.data.profile.chatrooms as chatroom (chatroom.id)}
        <Item {chatroom} />
      {:else}
        <div>沒人找你聊天, 可撥 QQ</div>
      {/each}
    </div>
  </Query>
{/if}
