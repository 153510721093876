<script>
  import { onMount, onDestroy } from 'svelte'
  import cable from 'helpers/cable'
  import isConnecting from 'stores/isConnecting'

  const channel = { channel: 'BreathChannel' }

  let initialized = false
  let subscription

  onMount(() => {
    subscription = cable.subscriptions.create(channel, {
      connected: () => {
        $isConnecting = true
        if (initialized) {
          document.body.dispatchEvent(new CustomEvent('reconnect'))
        } else {
          document.body.dispatchEvent(new CustomEvent('connect'))
        }
        initialized = true
      },
      disconnected: () => {
        $isConnecting = false
        document.body.dispatchEvent(new CustomEvent('disconnect'))
      }
    })
  })

  onDestroy(() => {
    subscription && subscription.unsubscribe()
  })
</script>
