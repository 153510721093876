<script>
  import { createEventDispatcher } from 'svelte'

  export let type, url, image, width, height, title, embedCode, description

  const dispatch = createEventDispatcher()

  function dispatchLoad () {
    dispatch('load')
  }

  function removeImage () {
    image = null
    dispatch('load')
  }

  function getThumbnailStyle () {
    // 舊訊息還沒有寬高資訊
    if (!width || !height) {
      // return { width: 100, height: 100 }
      return 'width: 100px; height: 100px;'
    }

    if (width > height) {
      // return { width: 100, height: 100 * height / width }
      return `width: 100px; height: ${parseInt(100 * height / width)}px;`
    } else {
      // return { width: 100 * width / height, height: 100 }
      return `width: ${parseInt(100 * width / height)}px; height: 100px;`
    }
  }
</script>

<style>
  .thumbnail-container {
    position: relative;
  }

  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>

{#if type === 'image'}
  {#if image}
    <div class='d-flex align-items-end'>
      <a href={url} class='img-thumbnail'>
        <div class='thumbnail-container' style={getThumbnailStyle()}>
          <img class='thumbnail' src={image} alt={title}
               on:load={dispatchLoad} on:error={removeImage} />
        </div>
      </a>
      <div class='btn-group btn-group-sm ml-1'>
        <a class='btn btn-outline-dark' target='_blank'
            href={`http://saucenao.com/search.php?db=999&url=${encodeURIComponent(image)}`}>
          黑詳
        </a>
        <a class='btn btn-outline-secondary' target='_blank'
            href={`https://ascii2d.net/search/url/${encodeURIComponent(image)}`}>
          白詳
        </a>
      </div>
    </div>
  {/if}
{/if}

{#if type === 'website'}
  <div class='card card-body p-1'>
    <div class='media'>
      <div class='media-body'>
        <h6 class='mb-0'>
          <a href={url}>{title}</a>
        </h6>
        {#if description}
          <div class='small text-force-wrap'>{description}</div>
        {/if}
      </div>
      {#if image}
        <div class='media-right ml-3'>
          <a href={url}>
            <div class='thumbnail-container' style={getThumbnailStyle()}>
              <img class='thumbnail' src={image} alt={title}
                   on:load={dispatchLoad} on:error={removeImage} />
            </div>
          </a>
        </div>
      {/if}
    </div>
  </div>
{/if}

{#if type === 'video'}
  <div>{@html embedCode}</div>
{/if}
