<script>
  import { onMount } from 'svelte'
  import GetChatroomQuery from 'components/GetChatroomQuery'
  import MessageSubscription from 'components/MessageSubscription'
  import MessageForm from './MessageForm'
  import { getClient } from 'helpers/client'
  import read from 'mutations/read'

  export let id

  const client = getClient()

  function markAsRead () {
    client.mutate({
      mutation: read,
      variables: {
        chatroomId: id
      }
    })
  }

  onMount(markAsRead)
</script>

<style>
  .fill {
    left: 1rem;
    right: 1rem;
    top: 0;
    bottom: 1rem;
  }
</style>

<GetChatroomQuery {id} let:chatroom>
  <div class='position-absolute d-flex flex-column fill'>
    <h1 class='h6'>和 {chatroom.fromUser.nickname} 聊天</h1>
    <div class='flex-1 overflow-y-scroll h-100'>
      <MessageSubscription channelName={chatroom.channelName}
          on:receive={markAsRead} />
    </div>
    <div class='flex-shrink-1 mt-1'>
      <MessageForm user={chatroom.fromUser} />
    </div>
  </div>
</GetChatroomQuery>
