<script>
  export let user
</script>

<div>
  {#if user.isAdmin}
    <span class='badge badge-danger'>管理員</span>
  {/if}
  {#if user.dgtaId}
    <span class='badge badge-primary'>元老編號 #{user.dgtaId}</span>
  {/if}
  {#if user.authedBy.length === 0}
    <span class='badge badge-secondary'>訪客帳號</span>
  {/if}
  {#if user.authedBy.length > 0}
    <span class='badge badge-success'>一般帳號</span>
  {/if}
</div>
