<script>
  import debounce from 'lodash.debounce'
  import { navigate } from 'svelte-routing'
  import getPopularBoards from 'queries/getPopularBoards'

  import Query from 'components/Query'
  import BoardLink from 'components/BoardLink'

  let keyword, debouncedKeyword

  const debounceKeyword = debounce(keyword => {
    debouncedKeyword = keyword
  }, 500)

  $: debounceKeyword(keyword)

  function enter () {
    navigate(`/boards/${encodeURIComponent(keyword)}`)
  }
</script>

<h1>主題列表</h1>

<div class='row'>
  <div class='col-xs-12 col-md-6 order-md-2'>
    <div class='card'>
      <div class='card-header'>建立/搜尋主題</div>
      <div class='card-body'>
        <div class='form-group'>
          <input
            type='text'
            class='form-control'
            placeholder='輸入關鍵字/名稱'
            bind:value={keyword} />
        </div>

        <button
          type='button'
          class='btn btn-primary'
          disabled={!keyword}
          on:click={enter}>
          進入
        </button>
      </div>
    </div>
  </div>

  <div class='col-xs-12 col-md-6 order-md-1'>
    <Query query={getPopularBoards}
        variables={{ keyword: debouncedKeyword }}
        let:result>
      <div class='list-group'>
        {#each result.data.popularBoards as board (board.id)}
          <BoardLink {board} />
        {/each}
      </div>
    </Query>
  </div>
</div>

