import { writable } from 'svelte/store'

export default function useStore (key, defaultValue) {
  let initialValue = localStorage.getItem(key)
  if (initialValue == null) {
    initialValue = defaultValue
  } else {
    initialValue = JSON.parse(initialValue)
  }

  const startStopNotifier = function (set) {
    const listener = function (event) {
      if (event.key === key) {
        set(JSON.parse(event.newValue))
      }
    }
    window.addEventListener('storage', listener)

    return function () {
      window.removeEventListener('storage', listener)
    }
  }

  const value = writable(initialValue, startStopNotifier)

  value.subscribe(function (value) {
    localStorage.setItem(key, JSON.stringify(value))
  })

  return value
}
