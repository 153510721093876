<script>
  import { link } from 'svelte-routing'

  import NavLink from './NavLink'
  import NotificationMenu from './NotificationMenu'
  import ProfileMenu from './ProfileMenu'

  let collapsed = true

  function toggle () {
    collapsed = !collapsed
  }
</script>

<nav class='navbar navbar-expand-lg navbar-light bg-light mb-3'>
  <a href='/' class='navbar-brand' use:link>無限期試做型</a>

  <button
    class='navbar-toggler'
    type='button'
    aria-controls='navbar-content'
    aria-expanded='false'
    aria-label='Toggle navigation'
    on:click={toggle}>
      <span class='navbar-toggler-icon'></span>
  </button>

  <div class='navbar-collapse' class:collapse={collapsed}>
    <ul class='navbar-nav'>
      <NavLink to='/boards'>主題</NavLink>
      <NavLink to='/wiki/首頁'>百科</NavLink>
      <NavLink to='/wishes'>願望</NavLink>
      <NavLink to='/donation'>贊助</NavLink>
      <NavLink to='/about'>關於</NavLink>
    </ul>

    <ul class='navbar-nav ml-auto'>
      <ProfileMenu />
      <NotificationMenu />
    </ul>
  </div>
</nav>
