<script>
  import imagePath from 'helpers/images'

  import PopularBoards from './PopularBoards'
  import GettingStarted from './GettingStarted'
  import Opening from './Opening'
</script>

<div class='row'>
  <div class='col-sm-9'>
    <h1>歡迎來到討戰網</h1>

    <Opening />

    <div class='row'>
      <div class='col-sm-6'>
        <PopularBoards />
      </div>
      <div class='col-sm-6'>
        <GettingStarted />
      </div>
    </div>
  </div>
  <div class='col-sm-3'>
    <img src={imagePath('./kanban.png')} alt='首頁圖' class='mw-100' />
  </div>
</div>
