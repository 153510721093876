<script>
  import GetProfileQuery from 'components/GetProfileQuery'
</script>

<h2>快速上手</h2>

<GetProfileQuery let:profile>
  <dl>
    {#if profile}
      <dt>我不想叫做 {profile.nickname}, 我要改名</dt>
      <dd>你可以從右上角的選單中點擊你目前的名字, 然後修改個人資料.</dd>
    {:else}
      <dt>我想說話</dt>
      <dd>你可以從右上角的選單中選擇一個登入方式.</dd>
    {/if}

    <dt>我不知道要去哪個主題</dt>
    <dd>先到最熱門的 [hot_topic] 主題看看吧, 那裡目前最多人了!</dd>

    <dt>我覺得我被霸凌了, 有人欺負我</dt>
    <dd>你可以告訴管理員, 盡量截圖存證, 我們會進行適當的處理.</dd>

    <dt>我使用公用電腦, 我想登出</dt>
    <dd>真拿你沒辦法, [登出], 會把你變成一個訪客帳號哦.</dd>
  </dl>
</GetProfileQuery>
