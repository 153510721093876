<script>
  import OpenChatroomMutation from 'components/OpenChatroomMutation'

  export let user
</script>

<OpenChatroomMutation {user} let:mutate>
  <button class='btn btn-secondary' on:click={mutate}>
    私人訊息
  </button>
</OpenChatroomMutation>
