<script>
  import { createClient } from 'helpers/client'
  import cable from 'helpers/cable'

  const clientPromise = createClient({
    endpoint: '/graphql',
    cable
  })
</script>

{#await clientPromise}
  (=ｘェｘ=)
{:then client}
  <slot {client} />
{:catch error}
  &gt;_&lt;
{/await}
