<script>
  import Query from 'components/Query'
  import getBoard from 'queries/getBoard'

  export let id
</script>

<Query query={getBoard} variables={{ id }} let:result>
  <slot board={result.data.board} />
</Query>
