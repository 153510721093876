<script>
  import { link } from 'svelte-routing'
  import Query from 'components/Query'
  import getPages from 'queries/getPages'
</script>

<h1>條目列表</h1>

<Query query={getPages} let:result>
  <ul>
    {#each result.data.pages as page (page.id)}
      <li><a href={`/wiki/${page.id}`} use:link>{page.title}</a></li>
    {/each}
  </ul>
</Query>
