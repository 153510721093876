<script>
  import GetChatroomQuery from 'components/GetChatroomQuery'
  import Chatroom from './Chatroom'
  import { updateChatroom, removeChatroom } from 'stores/openingChatrooms'

  export let id
  export let x = (window.innerWidth - 400) / 2
  export let y = (window.innerHeight - 400) / 2

  let container
  let draggable = false

  function handleDragStart (e) {
    e.dataTransfer.setData('text/plain', JSON.stringify({
      id,
      x,
      y,
      clientX: e.clientX,
      clientY: e.clientY
    }))
  }

  function handleMousedown () {
    updateChatroom(id, x, y)
    draggable = true
  }

  function handleMouseup () {
    draggable = false
  }
</script>

<style>
  .chatroom-box {
    width: 400px;
    max-width: 100%;
    height: 400px;
    max-height: 100%;
    z-index: 100;
  }

  .chatroom-close {
    cursor: pointer;
  }
</style>

<div class='chatroom-box bg-white position-fixed border'
    style={`left: ${x}px; top: ${y}px;`} {draggable}
    bind:this={container}
    on:dragstart={handleDragStart}>
  <GetChatroomQuery {id} let:chatroom>
    <div class='p-2 bg-dark text-light'
        on:mousedown={handleMousedown}
        on:mouseup={handleMouseup}>
      <div class='d-flex align-items-center'>
        <h1 class='h6 m-0 flex-1'>和 {chatroom.fromUser.nickname} 聊天</h1>
        <div class='text-danger chatroom-close'
            on:click={() => { removeChatroom(id) }}>X</div>
      </div>
    </div>
    <Chatroom {chatroom} />
  </GetChatroomQuery>
</div>
