import { setContext, getContext } from 'svelte'
import create from './create'

const key = 'Apollo Client'

export const createClient = create

export const setClient = client => setContext(key, client)

export const getClient = () => getContext(key)
