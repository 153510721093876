<script>
  import GetProfileQuery from 'components/GetProfileQuery'
  import getFullProfile from 'queries/getFullProfile'
  import Modal from 'components/Modal'
  import editProfile from 'stores/editProfile'
  import Form from './Form'

  let form

  function hideEditProfile () {
    $editProfile = false
  }

  function submit () {
    form.submit()
    $editProfile = false
  }
</script>

<Modal visible={$editProfile} on:close={hideEditProfile}>
  <div slot='title'>修改個人資料</div>
  <div slot='body'>
    <GetProfileQuery query={getFullProfile} let:profile>
      <Form {profile} bind:this={form} />
    </GetProfileQuery>
  </div>
  <div slot='footer'>
    <button class='btn btn-primary' on:click={submit}>送出</button>
  </div>
</Modal>
