<script>
  import { getClient } from 'helpers/client'
  import getMessages from 'queries/getMessages'

  export let board

  const client = getClient()

  async function clear () {
    const { messages } = await client.readQuery({
      query: getMessages,
      variables: { channelName: board.channelName }
    })

    client.writeQuery({
      query: getMessages,
      variables: { channelName: board.channelName },
      data: {
        messages: messages.slice(messages.length - 50)
      }
    })
  }
</script>

<button class='btn btn-sm btn-outline-secondary mr-1' type='button'
    on:click={clear}>
  清理訊息
</button>
