<script>
  import { getClient } from 'helpers/client'
  import GetProfileQuery from 'components/GetProfileQuery'
  import editProfile from 'stores/editProfile'
  import signUp from 'mutations/signUp'
  import { setAccessToken, clearAccessToken } from 'helpers/accessToken'

  const client = getClient()
  let showing = false
  let menu

  function toggle () {
    showing = !showing
  }

  function showLink (profile, provider) {
    return !profile || !profile.authedBy.includes(provider)
  }

  function clearMenu (e) {
    if (showing && !menu.contains(e.target)) {
      toggle()
    }
  }

  function showEditProfile () {
    toggle()
    $editProfile = true
  }

  async function createGuest () {
    const { data } = await client.mutate({
      mutation: signUp,
      variables: { signUpToken: 'guest' }
    })
    setAccessToken(data.signUp.accessToken)
  }
</script>

<GetProfileQuery let:profile>
  <div slot='loading'>
    <li class='nav-item'>
      <a href='javascript:' class='nav-link'>登入中...</a>
    </li>
  </div>

  <li class='nav-item dropdown' bind:this={menu}>
    <a
      class='nav-link dropdown-toggle'
      class:show={showing}
      href='javascript:'
      role='button'
      aria-haspopup='true'
      aria-expanded={showing}
      on:click={toggle}>
      {#if profile}
        {profile.nickname}
      {:else}
        未登入 OwO
      {/if}
    </a>
    <div class='dropdown-menu dropdown-menu-right' class:show={showing}>
      {#if profile}
        <a class='dropdown-item' href='javascript:' on:click={showEditProfile}>
          修改個人資料
        </a>
        <a class='dropdown-item' href='/uploads'>我上傳的圖片</a>
        <div class='dropdown-divider' />
      {/if}
      {#if !profile}
        <a class='dropdown-item' href='javascript:' on:click={createGuest}>
          建立訪客帳號
        </a>
      {/if}
      {#if showLink(profile, 'google_oauth2')}
        <a class='dropdown-item' href='/auth/google_oauth2'>使用 Google 帳號登入</a>
      {/if}
      {#if showLink(profile, 'facebook')}
        <a class='dropdown-item' href='/auth/facebook'>使用 Facebook 帳號登入</a>
      {/if}
      {#if showLink(profile, 'yahoo_oauth2')}
        <a class='dropdown-item' href='/auth/yahoo_oauth2'>使用 Yahoo! 帳號登入</a>
      {/if}
      <div class='dropdown-divider' />
      <a class='dropdown-item' href='javascript:' on:click={clearAccessToken}>
        登出
      </a>
    </div>
  </li>
</GetProfileQuery>

<svelte:window on:click={clearMenu} />
