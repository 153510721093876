<script>
  import dotProp from 'dot-prop-immutable'
  import Query from 'components/Query'
  import GetProfileQuery from 'components/GetProfileQuery'
  import showMessenger from 'stores/showMessenger'
  import getTotalUnreadCount from 'queries/getTotalUnreadCount'
  import unreadCountUpdated from 'subscriptions/unreadCountUpdated'

  function toggle () {
    $showMessenger = !$showMessenger
  }

  const subscribeToMore = {
    document: unreadCountUpdated,
    updateQuery: (prev, { subscriptionData }) =>
      dotProp.set(
        prev,
        'profile.totalUnreadCount',
        subscriptionData.data.unreadCountUpdated.totalUnreadCount
      )
  }
</script>

<GetProfileQuery let:profile>
  {#if profile}
    <Query query={getTotalUnreadCount} {subscribeToMore} let:result>
      <li class='nav-item'>
        <a class='nav-link' href='javascript:'on:click={toggle}>
          通知
          {#if result.data.profile.totalUnreadCount > 0}
            <span class='badge badge-danger badge-pill'>
              {result.data.profile.totalUnreadCount}
            </span>
          {/if}
        </a>
      </li>
    </Query>
  {/if}
</GetProfileQuery>
