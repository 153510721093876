<script>
  import { createEventDispatcher } from 'svelte'
  import dotProp from 'dot-prop-immutable'
  import { getClient } from 'helpers/client'
  import Query from 'components/Query'
  import getMessages from 'queries/getMessages'
  import messageReceived from 'subscriptions/messageReceived'
  import MessageScroller from './MessageScroller'

  export let channelName

  const dispatch = createEventDispatcher()
  const client = getClient()
  let scroller

  const subscribeToMore = {
    document: messageReceived,
    variables: { channelName },
    updateQuery: (prev, { subscriptionData }) => {
      dispatch('receive')
      return dotProp.set(
        prev,
        'messages',
        upsertMessage(
          prev.messages,
          subscriptionData.data.messageReceived.message
        )
      )
    }
  }

  function upsertMessage (messages, message) {
    if (messages.find(m => m.id === message.id)) {
      return messages.map(m => m.id === message.id ? message : m)
    } else {
      return [...messages, message]
    }
  }

  let fromId = null

  function handleReconnect () {
    if (fromId) {
      fetchLatestMessages(fromId)
      fromId = null
    }
  }

  async function fetchLatestMessages (from) {
    const currentResult = await client.readQuery({
      query: getMessages,
      variables: { channelName }
    })

    const fetchedResult = await client.query({
      query: getMessages,
      variables: { channelName, from }
    })

    client.writeQuery({
      query: getMessages,
      variables: { channelName },
      data: {
        messages: fetchedResult.data.messages
          .reduce(
            (messages, message) => upsertMessage(messages, message),
            currentResult.messages
          )
      }
    })

    const newMessages = fetchedResult.data.messages
    if (newMessages.length === 50) {
      await fetchLatestMessages(newMessages[newMessages.length - 1].id)
    }
  }

  function handleDisconnect () {
    if (!fromId) {
      fromId = Date.now()
    }
  }

  async function loadMore (e) {
    const currentResult = await client.readQuery({
      query: getMessages,
      variables: { channelName }
    })

    const firstMessage = currentResult.messages[0]

    if (!firstMessage) {
      return
    }

    const fetchedResult = await client.query({
      query: getMessages,
      variables: {
        channelName,
        to: firstMessage.id
      }
    })

    client.writeQuery({
      query: getMessages,
      variables: { channelName },
      data: {
        messages: [...fetchedResult.data.messages, ...currentResult.messages]
      }
    })

    setTimeout(() => {
      scroller.scrollTo(
        e.detail.firstChildEl.offsetTop - e.detail.containerEl.offsetTop
      )
    })
  }
</script>

<svelte:body on:reconnect={handleReconnect} on:disconnect={handleDisconnect} />

<Query query={getMessages} variables={{ channelName }} {subscribeToMore}
    let:result>
  <div slot='loading' class='flex-1 h-100'>載入中...</div>
  <MessageScroller messages={result.data.messages}
      on:reach-top={loadMore} bind:this={scroller} />
</Query>
