<script>
  import getChatroom from 'queries/getChatroom'
  import Query from 'components/Query'

  export let id
</script>

<Query query={getChatroom} variables={{ id }} let:result>
  <slot chatroom={result.data.chatroom}></slot>
</Query>
