<script>
  import { onMount } from 'svelte'
  import MessageSubscription from 'components/MessageSubscription'
  import MessageForm from './MessageForm'
  import { getClient } from 'helpers/client'
  import read from 'mutations/read'

  export let chatroom

  const client = getClient()

  function markAsRead () {
    client.mutate({
      mutation: read,
      variables: {
        chatroomId: chatroom.id
      }
    })
  }

  onMount(markAsRead)
</script>

<style>
  .fill {
    left: 0.5em;
    right: 0.5em;
    top: 2.5em;
    bottom: 0.5em;
  }
</style>

<div class='position-absolute d-flex flex-column fill'>
  <MessageSubscription channelName={chatroom.channelName}
      on:receive={markAsRead} />
  <MessageForm user={chatroom.fromUser} />
</div>
