<script>
  import { createEventDispatcher, beforeUpdate, afterUpdate } from 'svelte'
  import Message from './Message'

  export let messages

  const dispatch = createEventDispatcher()
  let div
  let autoscroll

  beforeUpdate(() => {
    if (div) {
      autoscroll = (div.offsetHeight + div.scrollTop) >= (div.scrollHeight - 20)
    } else {
      autoscroll = true
    }
  })

  afterUpdate(doScroll)

  function handleScroll (e) {
    if (e.target.scrollTop === 0) {
      dispatch('reach-top', {
        containerEl: div,
        firstChildEl: div.children[0]
      })
    }
  }

  function doScroll () {
    if (autoscroll) {
      div.scrollTo(0, div.scrollHeight)
    }
  }

  export function scrollTo (top) {
    div.scrollTo(0, top)
  }
</script>

<div class='flex-1 overflow-y-scroll h-100' data-force-blank
    bind:this={div} on:scroll={handleScroll}>
  {#each messages as message (message.id)}
    <Message {message} on:load={doScroll} />
  {/each}
</div>
