<script>
  import GetProfileQuery from 'components/GetProfileQuery'
  import { getClient } from 'helpers/client'
  import isMobile from 'stores/isMobile'
  import getIP from 'queries/getIP'
  import createPost from 'mutations/createPost'
  import openPost from 'mutations/openPost'
  import closePosts from 'mutations/closePosts'
  import Modal from 'components/Modal'
  import PostForm from './PostForm'

  export let board

  let visible = false
  let ip
  let post = {}

  async function build () {
    const response = await getClient().query({ query: getIP })
    const category = board.categories[0]
    ip = response.data.ip

    if (category) {
      post = {
        category: category.name,
        address: category.addressFormat.replace('%{ip}', ip),
        body: ''
      }
      visible = true
    } else {
      post = {
        address: ip,
        body: ''
      }
      visible = true
    }
  }

  async function open () {
    const response = await getClient().mutate({
      mutation: openPost,
      variables: { boardId: board.id }
    })
    if (!response.data.openPost.post) {
      build()
    }
  }

  function close () {
    getClient().mutate({
      mutation: closePosts,
      variables: { boardId: board.id }
    })
  }

  function hideModal () {
    visible = false
  }

  function handleFormUpdate (updates) {
    post = { ...post, ...updates.detail }
  }

  function handleSubmit () {
    getClient().mutate({
      mutation: createPost,
      variables: { boardId: board.id, ...post }
    })
    visible = false
  }
</script>

{#if !$isMobile}
  <GetProfileQuery let:profile>
    <div class='btn-group btn-group-sm mr-1'>
      <button class='btn btn-outline-secondary' type='button' disabled={!profile}
          on:click={build}>
        發表
      </button>
      <button class='btn btn-outline-secondary' type='button' disabled={!profile}
          on:click={open}>
        募集
      </button>
      <button class='btn btn-outline-secondary' type='button' disabled={!profile}
          on:click={close}>
        休戰
      </button>
    </div>
  </GetProfileQuery>
{/if}

<Modal visible={visible} on:close={hideModal}>
  <div slot='title'>發表</div>
  <div slot='body'>
    <PostForm {board} {post} {ip} on:change={handleFormUpdate} />
  </div>
  <div slot='footer'>
    <button type='button' class='btn btn-primary'
      on:click={handleSubmit}>發表</button>
  </div>
</Modal>
