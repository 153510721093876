<script>
  import Query from 'components/Query'
  import getBlockingUsers from 'queries/getBlockingUsers'

  export let user

  function isBlocking (profile, user) {
    return profile && profile.blockingUsers.find(u => u.id === user.id)
  }
</script>

<Query query={getBlockingUsers} fetchPolicy='cache-first' let:result>
  <div slot='loading'>
    <slot name='unblocked'></slot>
  </div>

  {#if isBlocking(result.data.profile, user)}
    <slot name='blocked'></slot>
  {:else}
    <slot name='unblocked'></slot>
  {/if}
</Query>
