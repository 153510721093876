<script>
  import dotProp from 'dot-prop-immutable'
  import Query from 'components/Query'
  import getBoardOpeningPosts from 'queries/getBoardOpeningPosts'
  import postUpdated from 'subscriptions/postUpdated'
  import Post from './Post'
  import noPosts from 'images/nep.png'

  export let board

  let query

  const subscribeToMore = {
    document: postUpdated,
    variables: { boardId: board.id },
    updateQuery: (prev, { subscriptionData }) => {
      const { post, event } = subscriptionData.data.postUpdated
      switch (event) {
        case 'created':
          return dotProp.set(
            prev,
            'board.openingPosts',
            [...prev.board.openingPosts, post]
          )
        case 'destroyed':
          return dotProp.set(
            prev,
            'board.openingPosts',
            prev.board.openingPosts.filter(p => p.id !== post.id)
          )
      }
    }
  }

  function refetch () {
    query && query.refetch()
  }
</script>

<svelte:body on:reconnect={refetch} />

<Query query={getBoardOpeningPosts} variables={{ id: board.id }}
    {subscribeToMore} bind:this={query} let:result>
  {#each result.data.board.openingPosts as post (post.id)}
    <Post post={post} />
  {:else}
    <img src={noPosts} alt='無發文' class='mw-100' width='270' />
  {/each}
</Query>
