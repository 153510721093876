<script>
  import { getClient } from 'helpers/client'

  import Loading from './Loading'

  export let query, variables, subscribeToMore
  export let fetchPolicy = 'cache-and-network'

  $: observable = getClient().watchQuery({
    query,
    variables,
    fetchPolicy
  })

  $: if (subscribeToMore) {
    observable.subscribeToMore(subscribeToMore)
  }

  export function refetch () {
    observable && observable.refetch()
  }
</script>

{#await $observable}
  <slot name='loading'>
    <Loading />
  </slot>
{:then result}
  {#if result && !result.loading}
    <slot {result}>
      <pre>{JSON.stringify(result, null, 2)}</pre>
    </slot>
  {:else}
    <slot name='loading'>
      <Loading />
    </slot>
  {/if}
{/await}
