<script>
  import copy from 'helpers/copy'
  import Avatar from 'components/Avatar'
  import UserLink from 'components/UserLink'
  import DateTime from 'components/DateTime'

  export let post

  function copyAddress () {
    copy(post.address)
  }
</script>

<div class='media'>
  <div class='media-left mr-1'>
    <Avatar user={post.user} />
  </div>
  <div class='media-body'>
    <div class='d-flex align-items-baseline'>
      <div class='flex-1'>
        <UserLink user={post.user}>
          {post.user.nickname}
        </UserLink>
      </div>
      <div class='flex-1 ml-1 mr-1'>
        {#if post.address}
          <button class='btn btn-sm btn-outline-secondary btn-block'
              on:click={copyAddress}>{post.address}</button>
        {/if}
      </div>
      <div class='flex-1'>
        {#if post.category}
          {post.category}
        {/if}
      </div>
    </div>

    <div>{@html post.bodyHTML}</div>

    <div class='small text-muted text-right'>
      <div class='d-inline'>發表於 <DateTime datetime={post.createdAt} /></div>,
      <div class='d-inline'>更新於 <DateTime datetime={post.updatedAt} /></div>
    </div>
  </div>
</div>
