import { InMemoryCache } from 'apollo-cache-inmemory'
import { CachePersistor } from 'apollo-cache-persist'

const SCHEMA_VERSION = '20191209' // Must be a string.
const SCHEMA_VERSION_KEY = 'apollo-schema-version'

export default async function () {
  const cache = new InMemoryCache()

  const persistor = new CachePersistor({
    cache,
    storage: localStorage
  })

  // Read the current schema version from localStorage.
  const currentVersion = localStorage.getItem(SCHEMA_VERSION_KEY)

  if (currentVersion === SCHEMA_VERSION) {
    // If the current version matches the latest version,
    // we're good to go and can restore the cache.
    await persistor.restore()
  } else {
    // Otherwise, we'll want to purge the outdated persisted cache
    // and mark ourselves as having updated to the latest version.
    await persistor.purge()
    await localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION)
  }

  return cache
}
