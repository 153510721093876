<script>
  import { createEventDispatcher } from 'svelte'

  export let board, post, ip
  const dispatch = createEventDispatcher()

  function updateCategory (event) {
    const category = event.target.value
    const { addressFormat } = board.categories.find(c => c.name === category)
    const address = addressFormat.replace('%{ip}', ip)
    dispatch('change', { category, address })
  }

  function updateAddress (event) {
    dispatch('change', { address: event.target.value })
  }

  function updateBody (event) {
    dispatch('change', { body: event.target.value })
  }
</script>

{#if board.categories.length}
  <div class='form-group'>
    <label for='category' class='form-control-label'>分類</label>
    <select name='category' class='form-control' value={post.category}
      on:input={updateCategory}>
      {#each board.categories as category (category.name)}
        <option value={category.name}>{category.name}</option>
      {/each}
    </select>
  </div>
{/if}

<div class='form-group'>
  <label for='address' class='form-control-label'>位置</label>
  <input type='text' name='address' class='form-control'
    value={post.address}
    on:input={updateAddress} />
</div>

<div class='form-group'>
  <label for='body' class='form-control-label'>內文</label>
  <textarea name='body' class='form-control'
    value={post.body}
    on:input={updateBody} />
</div>
