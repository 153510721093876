<script>
  function findLink (element) {
    while (element instanceof Element && !element.matches('a[href]')) {
      element = element.parentNode
    }
    if (element === document) {
      return
    }
    return element
  }

  function handleClick (event) {
    if (event.which !== 1) {
      return
    }

    const link = findLink(event.target)
    if (!link) {
      return
    }

    if (link.matches('a[href]') && !link.matches('[target]')) {
      if ((link.hostname !== '' && link.hostname !== location.hostname) ||
          isForced(link)) {
        event.preventDefault()
        event.stopPropagation()
        window.open(link.href)
      }
    }
  }

  function isForced (element) {
    while (element !== document) {
      if (element.hasAttribute('data-force-blank')) {
        return true
      }
      element = element.parentNode
    }
    return false
  }
</script>

<svelte:window on:click={handleClick} />
