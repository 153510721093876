<script>
  import News from './News'
  import Posts from './Posts'
  import Images from './Images'

  export let board

  let tab = 'posts'
</script>

<div class='flex-1 overflow-y-scroll h-100 ml-1'>
  <News {board} />

  <ul class='nav nav-tabs mb-1'>
    <li class='nav-item'>
      <a href='javascript:' class='nav-link' class:active={tab === 'posts'}
          on:click={() => { tab = 'posts' }}>文</a>
    </li>
    <li class='nav-item'>
      <a href='javascript:' class='nav-link' class:active={tab === 'images'}
          on:click={() => { tab = 'images' }}>圖</a>
    </li>
  </ul>

  {#if tab === 'posts'}
    <Posts {board} />
  {/if}
  {#if tab === 'images'}
    <Images {board} />
  {/if}
</div>
