<script>
  import Query from 'components/Query'
  import getProfile from 'queries/getProfile'

  export let fetchPolicy
  export let query = getProfile
</script>

<Query {query} {fetchPolicy} let:result>
  <slot profile={result.data.profile}></slot>
</Query>
