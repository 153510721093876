<script>
  import MessageForm from 'components/MessageForm'
  import { getClient } from 'helpers/client'
  import sendBoardMessage from 'mutations/sendBoardMessage'

  export let board

  function handleSubmit (e) {
    getClient().mutate({
      mutation: sendBoardMessage,
      variables: {
        boardId: board.id,
        body: e.detail.body
      }
    })
  }
</script>

<MessageForm on:submit={handleSubmit} />
