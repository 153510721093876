import Cookies from 'js-cookie'

export function setAccessToken (accessToken) {
  Cookies.set('accessToken', accessToken, { expires: 365 })
  location.reload()
}

export function clearAccessToken () {
  Cookies.remove('accessToken')
  location.reload()
}

export function getAccessToken () {
  return getAccessTokenFromCookie() ||
         getAccessTokenFromStorage() ||
         getAccessTokenFromMeta()
}

function getAccessTokenFromCookie () {
  return Cookies.get('accessToken')
}

function getAccessTokenFromStorage () {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    Cookies.set('accessToken', accessToken)
    localStorage.removeItem('accessToken')
  }
  return accessToken
}

function getAccessTokenFromMeta () {
  const meta = document.querySelector('meta[name="X-Access-Token"]')
  return meta && meta.content
}
