<script>
  import MessageForm from 'components/MessageForm'
  import { getClient } from 'helpers/client'
  import sendUserMessage from 'mutations/sendUserMessage'

  export let user

  const client = getClient()

  function handleSubmit (e) {
    client.mutate({
      mutation: sendUserMessage,
      variables: {
        userId: user.id,
        body: e.detail.body
      }
    })
  }
</script>

<MessageForm on:submit={handleSubmit} />
