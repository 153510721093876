<script>
  import { onMount, onDestroy } from 'svelte'
  import { getClient } from 'helpers/client'
  import breathe from 'mutations/breathe'

  export let board

  let id

  const interval = 15 * 1000
  const timer = setInterval(breath, interval)

  onMount(breath)

  onDestroy(() => {
    clearInterval(timer)
  })

  async function breath () {
    const response = await getClient().mutate({
      mutation: breathe,
      variables: { boardId: board.slug, id }
    })
    id = response.data.breathe.onlineUser.id
  }
</script>
