<script>
  import { createEventDispatcher } from 'svelte'
  import GetProfileQuery from 'components/GetProfileQuery'
  import upload from 'helpers/upload'

  const dispatch = createEventDispatcher()
  let body = ''
  let disabled = false
  let bodyInput, fileInput

  function handleKeydown (event) {
    if (event.which === 13) {
      handleSubmit()
    }
  }

  async function handlePaste (e) {
    disabled = true

    const image = findImageFromClipboardData(e.clipboardData)
    if (image) {
      const response = await upload(image)
      insertIntoBody(response.url)
    }

    disabled = false
    setTimeout(() => { bodyInput.focus() })
  }

  function findImageFromClipboardData (clipboardData) {
    if (typeof clipboardData === 'object') {
      const items = clipboardData.items || clipboardData.files || []

      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (item.kind !== 'string' && /^image\/.+$/.test(item.type)) {
          return item.getAsFile()
        }
      }
    }
  }

  function handleUpload () {
    fileInput.click()
  }

  async function loadImage (e) {
    disabled = true

    const response = await upload(e.target.files[0])
    insertIntoBody(response.url)

    disabled = false
    e.target.value = null
    setTimeout(() => { bodyInput.focus() })
  }

  function insertIntoBody (text) {
    const start = bodyInput.selectionStart
    const end = bodyInput.selectionEnd

    let spaceBeforeText = ''
    if (body.charAt(start - 1) !== ' ') {
      spaceBeforeText = ' '
    }
    body = body.slice(0, start) + spaceBeforeText + text + ' ' + body.slice(end)

    bodyInput.selectionStart = bodyInput.selectionEnd = start + text.length + 1
  }

  function handleSubmit () {
    if (body !== '') {
      dispatch('submit', { body })
      body = ''
    }
  }
</script>

<GetProfileQuery let:profile>
  {#if profile}
    <div class='input-group flex-shrink-1 mt-1'>
      <input type='text' class='form-control' required
          placeholder='按下 Enter 鍵送出' autocomplete='off' maxlength='500'
          disabled={disabled} autoFocus bind:this={bodyInput} bind:value={body}
          on:paste={handlePaste} on:keydown={handleKeydown}>
        <div class='input-group-append'>
          <button type='button' class='btn btn-outline-secondary'
              disabled={disabled} on:click={handleUpload}>
            上傳
          </button>
          <button type='button' class='btn btn-primary'
              disabled={disabled} on:click={handleSubmit}>
            送出
          </button>
        </div>
    </div>

    <input type='file' class='d-none' accept='image/*'
        on:change={loadImage} bind:this={fileInput} />
  {:else}
    <div class='input-group flex-shrink-1 mt-1'>
      <input type='text' class='form-control' required
          placeholder='你必須要登入才可以發言' autocomplete='off' maxlength='500'
          disabled>
    </div>
  {/if}
</GetProfileQuery>
