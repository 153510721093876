<script>
  import Query from 'components/Query'
  import getAttachments from 'queries/getAttachments'
</script>

<style>
  .image-item {
    height: 200px;
  }

  .image {
    object-fit: cover;
  }
</style>

<h1>我上傳的圖片</h1>

<Query query={getAttachments} let:result>
  <div class='d-flex flex-wrap'>
    {#each result.data.attachments as attachment (attachment.id)}
      <a href={attachment.url} target='_blank'
         class='image-item d-inline-block'>
        <img src={attachment.url}
             alt={attachment.url}
             class='image mw-100 mh-100' />
      </a>
    {/each}
  </div>
</Query>
