<script>
  import LayoutButton from './LayoutButton'
  import ShowImageButton from './ShowImageButton'
  import ClearButton from './ClearButton'
  import PostButtons from './PostButtons'
  import ConnectionState from './ConnectionState'

  export let board
</script>

<div class='btn-toolbar my-1'>
  <LayoutButton />
  <ShowImageButton />
  <ClearButton {board} />
  <PostButtons {board} />
  <ConnectionState />
</div>
