import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { getAccessToken } from 'helpers/accessToken'

export default endpoint => {
  const httpLink = createHttpLink({
    uri: endpoint
  })

  const authLink = setContext(function (_, { headers }) {
    const accessToken = getAccessToken()
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : ''
      }
    }
  })

  return authLink.concat(httpLink)
}
