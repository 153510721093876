<script>
  import Portal from 'components/Portal'

  import { createEventDispatcher } from 'svelte'
  import { fade, fly } from 'svelte/transition'

  export let visible
  let backdrop
  const dispatch = createEventDispatcher()

  function handleClose (event) {
    if (event.target === backdrop) {
      close()
    }
  }

  function close () {
    dispatch('close')
  }
</script>

<Portal>
  {#if visible}
    <div class='modal-backdrop show'
        transition:fade={{ duration: 200 }}></div>

    <div class='modal d-block' tabindex='-1' role='dialog'
        on:click={handleClose} bind:this={backdrop}>
      <div class='modal-dialog' role='document'
          transition:fly={{ y: -500, duration: 200 }}>
        <div class='modal-content'>
          <div class='modal-header'>
            <h5 class='modal-title'>
              <slot name='title'></slot>
            </h5>
            <button type='button' class='close' data-dismiss='modal'
                aria-label='Close' on:click={close}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div class='modal-body'>
            <slot name='body'></slot>
          </div>
          <div class='modal-footer'>
            <slot name='footer'></slot>
          </div>
        </div>
      </div>
    </div>
  {/if}
</Portal>
