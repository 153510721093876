<script>
  import dotProp from 'dot-prop-immutable'
  import Query from 'components/Query'
  import getBoardOnlineUsers from 'queries/getBoardOnlineUsers'
  import onlineUserUpdated from 'subscriptions/onlineUserUpdated'
  import UserLink from 'components/UserLink'
  import Avatar from 'components/Avatar'

  export let board

  let query

  function reduceList (onlineUsers) {
    return onlineUsers.reduce((list, onlineUser) => {
      if (list.find(u => u.id === onlineUser.user.id)) {
        return list
      }
      return list.concat(onlineUser.user)
    }, [])
  }

  const subscribeToMore = {
    document: onlineUserUpdated,
    variables: { boardId: board.id },
    updateQuery: (prev, { subscriptionData }) => {
      const { onlineUser, event } = subscriptionData.data.onlineUserUpdated
      switch (event) {
        case 'created':
          return dotProp.set(
            prev,
            'board.onlineUsers',
            [...prev.board.onlineUsers, onlineUser]
          )
        case 'destroyed':
          return dotProp.set(
            prev,
            'board.onlineUsers',
            prev.board.onlineUsers.filter(ou => ou.id !== onlineUser.id)
          )
      }
    }
  }

  function refetch () {
    query && query.refetch()
  }
</script>

<style>
  .online-users {
    width: 100px;
  }
</style>

<svelte:body on:reconnect={refetch} />

<div class='overflow-y-scroll h-100 online-users mr-1'>
  <Query query={getBoardOnlineUsers} variables={{ id: board.id }}
      {subscribeToMore} bind:this={query} let:result>
    {#each reduceList(result.data.board.onlineUsers) as user (user.id)}
      <UserLink {user}>
        <div class='text-center small'>
          <Avatar user={user} />
          <div class='text-truncate'>{user.nickname}</div>
        </div>
      </UserLink>
    {/each}
  </Query>
</div>
