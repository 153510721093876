<script>
  import OpenChatroomMutation from 'components/OpenChatroomMutation'
  import Avatar from 'components/Avatar'

  export let chatroom
</script>

<style>
  .unread-count {
    right: 0;
    bottom: 0;
  }

  .messenger-item {
    padding: 0.25em;
  }

  .messenger-item-nickname {
    width: 5em;
  }

  .messenger-item:hover {
    background-color: #ffd;
    cursor: pointer;
  }
</style>

<OpenChatroomMutation user={chatroom.fromUser} let:mutate>
  <div class='d-flex align-items-center messenger-item' on:click={mutate}>
    <div class='position-relative'>
      <Avatar user={chatroom.fromUser} class='img-thumbnail rounded-circle' />
      {#if chatroom.unreadCount > 0}
        <div class='position-absolute unread-count'>
          <span class='badge badge-danger'>{chatroom.unreadCount}</span>
        </div>
      {/if}
    </div>
    <div class='messenger-item-nickname mx-1 text-truncate'>
      {chatroom.fromUser.nickname}
    </div>
  </div>
</OpenChatroomMutation>
